// Español
export const esResultsTable = {
    table:{
        report: 'Reporte',
        created_at: 'Creado',
        preview: 'Previsualización',
        empty: 'No se han encontrado resultados.',
        loading: 'Cargando datos de resultados. Espere por favor.',
        search: 'Búsqueda por palabra clave',
    }
}

// Español peruano
export const peResultsTable = {
    table:{
        report: 'Reporte',
        created_at: 'Creado',
        preview: 'Previsualización',
        empty: 'No se han encontrado resultados.',
        loading: 'Cargando datos de resultados. Espere por favor.',
        search: 'Búsqueda por palabra clave',
    }
}

// English
export const enResultsTable = {
    table:{
        report: 'Report',
        created_at: 'Created At',
        preview: 'Preview',
        empty: 'No results found.',
        loading: 'Loading results data. Please wait.',
        search: 'Keyword Search',
    }
}