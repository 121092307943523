// Español
export const esItem = {
    employees: 'Colaboradores',
    schedule: 'Horarios',
    timecard: 'Hoja de horas',
    reports: 'Reportes',
    request: 'Solicitudes',
    overtime: 'Horas extras',
}

export const esSubItem = {
    generator: 'Generador',
    results: 'Resultados'
}

export const esSideBottom = {
    totals: {
        title: 'Totales',
        paycode: 'Código de pago',
        costcenter: 'Centro de Costo',
        amount: 'Monto',
        empty: 'No se encontraron totales.',
        loading: 'Cargando datos de totales. Espere por favor.'
    },
    audit: {
        title: 'Auditoría',
        register: 'Registro',
        date: 'Fecha',
        time: 'Hora',
        action: 'Acción',
        status: 'Estado',
        message: 'Mensaje',
        user: 'Usuario',
        file: 'Archivo',
        empty: 'No se encontró ninguna auditoría.',
        loading: 'Cargando datos de auditoría. Espere por favor.',
        by_event_date: 'Por fecha de evento',
        employee: 'Empleado',
        params: 'Parámetros',
    },
}

export const esDialogPassword = {
    title: 'Cambio de contraseña',
    currentPassword: 'Contraseña actual',
    newPassword: 'Nueva Contraseña',
    repeatPassword: 'Repetir Contraseña',
    singout: 'Cerrar sesión'
}

export const esDialog = {
    yes: 'Si',
    no: 'No',
    cancel: 'Cancelar',
    save: 'Guardar',
    add: 'Agregar',
    send: 'Enviar',
}

export const esCalendar = {
    months: {
        names: {
            january: 'Enero',
            february: 'Febrero',
            march: 'Marzo',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre'
        },
        shorts: {
            january: 'Ene',
            february: 'Feb',
            march: 'Mar',
            april: 'Abr',
            may: 'May',
            june: 'Jun',
            july: 'Jul',
            august: 'Ago',
            september: 'Sep',
            october: 'Oct',
            november: 'Nov',
            december: 'Dic'
        }
    },
    days:{
        names: {
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado'
        },
        shorts: {
            sunday: 'DO',
            monday: 'LU',
            tuesday: 'MA',
            wednesday: 'MI',
            thursday: 'JU',
            friday: 'VI',
            saturday: 'SA'
        },
        min: {
            sunday: 'D',
            monday: 'L',
            tuesday: 'M',
            wednesday: 'X',
            thursday: 'J',
            friday: 'V',
            saturday: 'S'
        }
    }
}

export const esDateTime = {
    fullisoformat: 'YYYY-MM-DD HH:mm:ss',
    isoformat: 'YYYY-MM-DD',
    fullformat: 'DD-MM-YYYY HH:mm',
    format: 'DD-MM-YYYY',
}

// Español peruano
export const peItem = {
    employees: 'Colaboradores',
    schedule: 'Horarios',
    timecard: 'Hoja de horas',
    reports: 'Reportes',
    request: 'Solicitudes',
    overtime: 'Horas extras'
}

export const peSubItem = {
    generator: 'Generador',
    results: 'Resultados'
}

export const peSideBottom = {
    totals: {
        title: 'Totales',
        paycode: 'Código de pago',
        costcenter: 'Centro de Costo',
        amount: 'Monto',
        empty: 'No se encontraron totales.',
        loading: 'Cargando datos de totales. Espere por favor.'
    },
    audit: {
        title: 'Auditoría',
        register: 'Registro',
        date: 'Fecha',
        time: 'Hora',
        action: 'Acción',
        status: 'Estado',
        message: 'Mensaje',
        user: 'Usuario',
        file: 'Archivo',
        empty: 'No se encontró ninguna auditoría.',
        loading: 'Cargando datos de auditoría. Espere por favor.',
        by_event_date: 'Por fecha de evento',
        employee: 'Empleado',
        params: 'Parámetros',
    },
}

export const peDialogPassword = {
    title: 'Cambio de contraseña',
    currentPassword: 'Contraseña actual',
    newPassword: 'Nueva Contraseña',
    repeatPassword: 'Repetir Contraseña',
    singout: 'Cerrar sesión'
}

export const peDialog = {
    yes: 'Si',
    no: 'No',
    cancel: 'Cancelar',
    save: 'Guardar',
    add: 'Agregar',
    send: 'Enviar',
}

export const peCalendar = {
    months: {
        names: {
            january: 'Enero',
            february: 'Febrero',
            march: 'Marzo',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre'
        },
        shorts: {
            january: 'Ene',
            february: 'Feb',
            march: 'Mar',
            april: 'Abr',
            may: 'May',
            june: 'Jun',
            july: 'Jul',
            august: 'Ago',
            september: 'Sep',
            october: 'Oct',
            november: 'Nov',
            december: 'Dic'
        }
    },
    days:{
        names: {
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado'
        },
        shorts: {
            sunday: 'DO',
            monday: 'LU',
            tuesday: 'MA',
            wednesday: 'MI',
            thursday: 'JU',
            friday: 'VI',
            saturday: 'SA'
        },
        min: {
            sunday: 'D',
            monday: 'L',
            tuesday: 'M',
            wednesday: 'X',
            thursday: 'J',
            friday: 'V',
            saturday: 'S'
        }
    }
}

export const peDateTime = {
    fullisoformat: 'YYYY-MM-DD HH:mm:ss',
    isoformat: 'YYYY-MM-DD',
    fullformat: 'DD-MM-YYYY HH:mm',
    format: 'DD-MM-YYYY',
}

// English
export const enItem = {
    employees: 'Employees',
    schedule: 'Schedule',
    timecard: 'Timecard',
    reports: 'Reports',
    request: 'Requests',
    overtime: 'Overtime'
}

export const enSubItem = {
    generator: 'Generator',
    results: 'Results'
}

export const enSideBottom = {
    totals: {
        title: 'Totals',
        paycode: 'Paycode',
        costcenter: 'Cost Center',
        amount: 'Amount',
        empty: 'No totals found.',
        loading: 'Loading totals data. Please wait.'
    },
    audit: {
        title: 'Audit',
        register: 'Register',
        date: 'Date',
        time: 'Time',
        action: 'Action',
        status: 'Status',
        message: 'Message',
        user: 'User',
        file: 'File',
        empty: 'No audit found.',
        loading: 'Loading audit data. Please wait.',
        by_event_date: 'By event date',
        employee: 'Employee',
        params: 'Params',
    },
}

export const enDialogPassword = {
    title: 'Change you Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    repeatPassword: 'Repeat password',
    singout: 'Logout'
}

export const enDialog = {
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    save: 'Save',
    add: 'Add',
    send: 'Send',
}

export const enCalendar = {
    months: {
        names: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December'
        },
        shorts: {
            january: 'Jan',
            february: 'Feb',
            march: 'Mar',
            april: 'Apr',
            may: 'May',
            june: 'Jun',
            july: 'Jul',
            august: 'Aug',
            september: 'Sep',
            october: 'Oct',
            november: 'Nov',
            december: 'Dec'
        }
    },
    days:{
        names: {
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday'
        },
        shorts: {
            sunday: 'Sun',
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat'
        },
        min: {
            sunday: 'S',
            monday: 'M',
            tuesday: 'T',
            wednesday: 'W',
            thursday: 'T',
            friday: 'F',
            saturday: 'S'
        }
    }
}

export const enDateTime = {
    fullisoformat: 'YYYY-MM-DD HH:mm:ss',
    isoformat: 'YYYY-MM-DD',
    fullformat: 'MM-DD-YYYY HH:mm',
    format: 'MM-DD-YYYY',
}