// Español
export const esLabels = {
    wellcome: 'Bienvenido!',
    username: 'Usuario',
    password: 'Contraseña',
    version: 'versión',
    microsoft: 'Iniciar sesión con Microsoft',
    identifier: 'Identificador'
}

export const esErrors ={
    login: 'El nombre de usuario o/y la contraseña son incorrectos.'
}

export const esBtns = {
    signin: 'Inicio de sesión'
}

// Español peru
export const peLabels = {
    wellcome: 'Bienvenido!',
    username: 'Usuario',
    password: 'Contraseña',
    version: 'versión',
    microsoft: 'Iniciar sesión con Microsoft',
    identifier: 'Identificador'
}

export const peErrors ={
    login: 'El nombre de usuario o/y la contraseña son incorrectos.'
}

export const peBtns = {
    signin: 'Inicio de sesión'
}


// English
export const enLabels = {
    wellcome: 'Wellcome!',
    username: 'Username',
    password: 'Password',
    version: 'version',
    microsoft: 'Sing in with Microsoft',
    identifier: 'Identifier'
}

export const enErrors ={
    login: 'Wrong username or password'
}

export const enBtns = {
    signin: 'Sign In'
}