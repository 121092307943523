// Español
export const esTimeTable = {
    table: {
        name: 'Nombre',
        date: 'Fecha',
        shifts: 'Turnos',
        start: 'Entrada',
        end: 'Salida',
        exceptions: 'Excepciones',
        paycodes: 'Códigos de pago',
        totalsHours: 'Horas totales',
        empty: 'No se encontró ninguna la hoja de horas.',
        absences: 'Ausencia Injustificada',
        placeholder: {
            paycode: 'Seleccione un código de pago',
        },
        dialog_approve: {
            title: 'Horas para aprobar',
            date: 'Fecha',
            amount_to_approve: 'Cantidad por aprobar',
            button: 'Gestionar horas',
            all: 'Todas',
            none: 'Ninguna',
            some: 'Alguna (HH:mm)',
            error1: {
                title: 'Cantidad',
                message: 'La cantidad por aprobar es inválida'
            },
            error2: {
                title: 'Campos vacíos',
                message: 'Por favor rellenar todos los campos requeridos'
            }
        },
        dialog_retract:{
            title: "¿Está seguro de retractar la hora {amount_in_time} - {paycode_name}?"
        },
        dialog_addpaycode: {
            date: 'Fecha',
            paycode: 'Código de pago',
            start: 'Comienza a las',
            amount: 'Cantidad (HH:mm)',
            button: 'Agregar código de pago',
            all: 'Todas',
            none: 'Ninguna',
            some: 'Alguna (HH:mm)',
            error1: {
                title: 'Campos vacíos',
                message: 'Por favor rellenar todos los campos requeridos'
            },
            error2: {
                title: 'Error',
                message: 'No se pudo agregar el código de pago'
            },
            toast:{
                addPayCode:{
                    title: 'Agregar código de pago',
                    warning: 'No se pueden modificar elementos del horario anterior a la fecha de contratación.',
                    success: 'Código de pago generado exitosamente',
                }
            },
            upload:"Subir archivo",
            dnd: "Arrastre y suelte los archivos aquí para cargarlos."
        },
        errors:{
            17224: {
                title: 'Período Cerrado',
                message: 'No se pueden modificar periodos cerrados'
            },
            get_smart_groups: "Error al intentar obtener los smartgroups",
            get_employees: "Error al intentar obtener todos los empleados",
            get_schedule: "Error al obtener el los turnos del empleado",
            get_exceptions: "Error al obtener las excepciones",
            get_timecard: "Error al obtener la hoja de horas",
            get_audit: "Error al obtener la auditoría",
            get_paycode: "Error al obtener los códigos de pago",
            get_multi: "Error al obtener los códigos de pago del horario",
        },
        exception: {
            UNSCHEDULED: "No Planificado",
            MISSED_PUNCH: "Marcaje omitido",
            MISSED_OUT_PUNCH: "Marcaje de salida omitido",
            CANCELED_DEDUCT: "Deducción cancelada",
            LATE:{
                START: "Entrada Tardía",
                END: "Salida Tardía",
            },
            EARLY:{
                START: "Entrada Temprana",
                END: "Salida Temprana",
            },
            VERY_EARLY: "Entrada muy Temprana",
            VERY_LATE: "Entrada muy Tardía",
            INVALID_NAMED_DURATION: "Duración Inválida",
            LONG_TOTAL_BREAK: "Descanso prolongado",
            SHORT_TOTAL_BREAK: "Descanso acortado",
            LONG: "Descanso prolongado",
            SHORT: "Descanso acortado",
        }
    },
    filter: {
        allGroups: 'Todos los grupos'
    },
}

// Español peruano
export const peTimeTable = {
    table: {
        name: 'Nombre',
        date: 'Fecha',
        shifts: 'Turnos',
        start: 'Entrada',
        end: 'Salida',
        exceptions: 'Excepciones',
        paycodes: 'Códigos de pago',
        totalsHours: 'Horas totales',
        empty: 'No se encontró ninguna la hoja de horas.',
        absences: 'Ausencia Injustificada',
        placeholder: {
            paycode: 'Seleccione un código de pago',
        },
        dialog_approve: {
            title: 'Horas para aprobar',
            date: 'Fecha',
            amount_to_approve: 'Cantidad por aprobar',
            button: 'Gestionar horas',
            all: 'Todas',
            none: 'Ninguna',
            some: 'Alguna (HH:mm)',
            error1: {
                title: 'Cantidad',
                message: 'La cantidad por aprobar es inválida'
            },
            error2: {
                title: 'Campos vacíos',
                message: 'Por favor rellenar todos los campos requeridos'
            }
        },
        dialog_retract:{
            title: "¿Está seguro de retractar la hora {amount_in_time} - {paycode_name}?"
        },
        dialog_addpaycode: {
            date: 'Fecha',
            paycode: 'Código de pago',
            start: 'Comienza a las',
            amount: 'Cantidad (HH:mm)',
            button: 'Agregar código de pago',
            all: 'Todas',
            none: 'Ninguna',
            some: 'Alguna (HH:mm)',
            error1: {
                title: 'Campos vacíos',
                message: 'Por favor rellenar todos los campos requeridos'
            },
            error2: {
                title: 'Error',
                message: 'No se pudo agregar el código de pago'
            },
            toast:{
                addPayCode:{
                    title: 'Agregar código de pago',
                    warning: 'No se pueden modificar elementos del horario anterior a la fecha de contratación.',
                    success: 'Código de pago generado exitosamente',
                }
            },
            upload:"Subir archivo",
            dnd: "Arrastre y suelte los archivos aquí para cargarlos."
        },
        errors:{
            17224: {
                title: 'Período Cerrado',
                message: 'No se pueden modificar periodos cerrados'
            },
            get_smart_groups: "Error al intentar obtener los smartgroups",
            get_employees: "Error al intentar obtener todos los empleados",
            get_schedule: "Error al obtener el los turnos del empleado",
            get_exceptions: "Error al obtener las excepciones",
            get_timecard: "Error al obtener la hoja de horas",
            get_audit: "Error al obtener la auditoría",
            get_paycode: "Error al obtener los códigos de pago",
            get_multi: "Error al obtener los códigos de pago del horario",
        },
        exception: {
            UNSCHEDULED: "No Planificado",
            MISSED_PUNCH: "Marcaje omitido",
            MISSED_OUT_PUNCH: "Marcaje de salida omitido",
            CANCELED_DEDUCT: "Deducción cancelada",
            LATE:{
                START: "Entrada Tardía",
                END: "Salida Tardía",
            },
            EARLY:{
                START: "Entrada Temprana",
                END: "Salida Temprana",
            },
            VERY_EARLY: "Entrada muy Temprana",
            VERY_LATE: "Entrada muy Tardía",
            INVALID_NAMED_DURATION: "Duración Inválida",
            LONG_TOTAL_BREAK: "Descanso prolongado",
            SHORT_TOTAL_BREAK: "Descanso acortado",
            LONG: "Descanso prolongado",
            SHORT: "Descanso acortado",
        }
    },
    filter: {
        allGroups: 'Todos los grupos'
    },
}

// English
export const enTimeTable = {
    table: {
        name: 'Name',
        date: 'Date',
        shifts: 'Shifts',
        start: 'Start',
        end: 'End',
        exceptions: 'Exceptions',
        paycodes: 'Pay codes',
        totalsHours: 'Totals hours',
        empty: 'No time card  was found.',
        absences: 'Unjustified absence',
        placeholder: {
            paycode: 'select a paycode',
        },
        dialog_approve: {
            title: 'Hours to approve',
            date: 'Date',
            amount_to_approve: 'amount to approve',
            button: 'Manage hours',
            all: 'All',
            none: 'None',
            some: 'Some (HH:mm)',
            error1: {
                title: 'Amount',
                message: 'The amount to be approved is invalid'
            },
            error2: {
                title: 'Empty fields',
                message: 'Please fill in all the required fields'
            }
        },
        dialog_retract:{
            title: "Are you sure you want to retract the time {amount_in_time} - {paycode_name}?"
        },
        dialog_addpaycode: {
            date: 'Date',
            paycode: 'Paycode',
            start: 'Starts at',
            amount: 'Amount (HH:mm)',
            button: 'Add paycode',
            all: 'All',
            none: 'None',
            some: 'Some (HH:mm)',
            error1: {
                title: 'Empty fields',
                message: 'Please fill in all the required fields'
            },
            error2: {
                title: 'Error',
                message: 'Failed to add paycode',
            },
            toast:{
                addPayCode:{
                    title: 'Add paycode',
                    warning: 'Elements of the schedule prior to the hiring date cannot be modified.',
                    success: 'paycode successfully generated',
                }
            },
            upload:"Upload file",
            dnd: "Drag and drop files here to upload."
        },
        errors:{
            17224: {
                title: 'Closed Period',
                message: 'It is not possible to modify closed periods'
            },
            get_smart_groups: "Error when trying to get the smart groups",
            get_employees: "Error when trying to get all employees of the smartgroup",
            get_schedule: "Error getting employee shifts",
            get_exceptions: "Failed to get exceptions",
            get_timecard: "Error getting timecard",
            get_audit: "Failed to get audit",
            get_paycode: "Error getting pay codes",
            get_multi: "Error when obtaining the pay codes of the schedule",
        },
        exception: {
            UNSCHEDULED: "Unscheduled",
            MISSED_PUNCH: "Missed punch",
            MISSED_OUT_PUNCH: "Missed out punch",
            CANCELED_DEDUCT: "Canceled Deduct",
            LATE:{
                START: "Late in",
                END: "Late out",
            },
            EARLY:{
                START: "Early in",
                END: "Early out",
            },
            VERY_EARLY: "Very Early",
            VERY_LATE: "Very Late",
            INVALID_NAMED_DURATION: "Invalid Named Duration",
            LONG_TOTAL_BREAK: "Long break",
            SHORT_TOTAL_BREAK: "Short break",
            LONG: "Long break",
            SHORT: "Short break",
        }
    },
    filter: {
        allGroups: 'All Groups'
    },

}
