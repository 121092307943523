import { createI18n } from 'vue-i18n';

import * as generalTranslations from './general/translations';
import * as loginTranslations from './login/translations';
import * as reportsTranslations from './reports/translations';
import * as resultsTranslations from './results/translations';
import * as timecardTranslations from './timecard/translations';
import * as scheduleTranslations from './schedule/translations';
import * as employeeTranslations from './employee/translations';
import * as errorTranslations from './error/translations';
import * as requestTranslations from './request/translations';
import * as overtimeTranslations from './overtime/translations';

const messages = {};
const languages = ['en', 'es', 'pe'];

languages.forEach(language => {
    messages[language] = {
        login: {
            labels: loginTranslations[`${language}Labels`],
            buttons: loginTranslations[`${language}Btns`],
            errors: loginTranslations[`${language}Errors`],
        },
        general: {
            items: generalTranslations[`${language}Item`],
            subitems: generalTranslations[`${language}SubItem`],
            sideBottom: generalTranslations[`${language}SideBottom`],
            dialogpassword: generalTranslations[`${language}DialogPassword`],
            dialog: generalTranslations[`${language}Dialog`],
            calendar: generalTranslations[`${language}Calendar`],
            datetime: generalTranslations[`${language}DateTime`],
        },
        timecard: {
            timetable: timecardTranslations[`${language}TimeTable`],
        },
        reports: {
            stepper: resultsTranslations[`${language}Stepper`],
        },
        results: {
            resultstable: reportsTranslations[`${language}ResultsTable`],
        },
        schedule: {
            scheduleTable: scheduleTranslations[`${language}ScheduleTable`],
        },
        employee: {
            employeeTable: employeeTranslations[`${language}EmployeeTable`],
        },
        error404: {
            error: errorTranslations[`${language}Error`],
        },
        overtime: {
            overTable: overtimeTranslations[`${language}OverTable`],
        },
        request: {
            requestTable: requestTranslations[`${language}RequestTable`],
        },
    };
})

console.log("i18nv2")
console.log(messages)

export const i18n = createI18n({
    legacy: false,
    locale: undefined,
    messages,
});