// Español
export const esOverTable = {
    table: {
        fullname: 'Nombre completo',
        person_num: 'Número personal',
        date: 'Fecha',
        schedule: 'Horario',
        punches: 'Marcas',
        action: 'Acción',
        amount: 'Cantidad',
        partial: 'Cantidad parcial',
        result: 'Resultado',
        retry: 'Reintentar',
        comment: 'Comentario',
        note: 'Nota',
    },
    select:{
        placeholder: "Selecciona una acción",
        approve_all: "Aprobar todo",
        partially_approve: "Aprobar parcialmente",
        reject_all: "Rechazar todo"
    },
    comment: {
        placeholder: "Agrega un comentario",
    },
    note: {
        placeholder: "Agrega una nota",
    },
    mcomment:{
        title: "Agregar comentario",
    },
    modal: {
        title: "Confirmación",
        message: "Estos cambios serán aplicados y NO son reversibles desde esta vista, ¿Estás seguro de aplicarlos?",
        buttons: {
            apply: "aplicar"
        }
    },
    results: {
        title: "Resultados"
    }
}

// i18pe
export const peOverTable = {
    table: {
        fullname: 'Nombre completo',
        person_num: 'Número personal',
        date: 'Fecha',
        schedule: 'Horario',
        punches: 'Marcas',
        action: 'Acción',
        amount: 'Cantidad',
        partial: 'Cantidad parcial',
        result: 'Resultado',
        retry: 'Reintentar',
        comment: 'Comentario',
        note: 'Nota',
    },
    select:{
        placeholder: "Selecciona una acción",
        approve_all: "Aprobar todo",
        partially_approve: "Aprobar parcialmente",
        reject_all: "Rechazar todo"
    },
    comment: {
        placeholder: "Agrega un comentario",
    },
    note: {
        placeholder: "Agrega una nota",
    },
    mcomment:{
        title: "Agregar comentario",
    },
    modal: {
        title: "Confirmación",
        message: "Estos cambios serán aplicados y NO son reversibles desde esta vista, ¿Estás seguro de aplicarlos?",
        buttons: {
            apply: "aplicar"
        }
    },
    results: {
        title: "Resultados"
    }
}


// English
export const enOverTable = {
    table: {
        fullname: 'Fullname',
        person_num: 'Person number',
        date: 'Date',
        schedule: 'Schedule',
        punches: 'Punches',
        action: 'Action',
        amount: 'Amount',
        partial: 'Partial Amount',
        result: 'Result',
        retry: 'Retry',
        comment: 'Comment',
        note: 'Note',
    },
    select:{
        placeholder: "Select an action",
        approve_all: "Approve all",
        partially_approve: "Partially approve",
        reject_all: "Reject all"
    },
    comment: {
        placeholder: "Add a comment",
    },
    note: {
        placeholder: "Add a note",
    },
    mcomment:{
        title: "Add comment",
    },
    modal: {
        title: "Confirmation",
        message: "These changes will be applied and are NOT reversible from this view, are you sure to apply them?",
        buttons: {
            apply: "apply"
        }
    },
    results: {
        title: "Results"
    }
}
