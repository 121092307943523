// Español
export const esRequestTable = {
    table: {
        person_number: 'Número personal',
        fullname: 'Nombre completo',
        paycode: 'Código de pago',
        create_at: 'Fecha creación',
        startdate: 'Fecha inicio',
        endate: 'Fecha término',
        manager: 'Manager',
        status: 'Estado',
        kronostatus: 'Kronos estado',
    },
    menu:{
        accept: 'Aceptar',
        reject: 'Rechazar',
        refuse: 'Rehusar',
        information: 'Información',
        detail: 'Detalle',
        add: 'Agregar'
    },
    filter:{
        allGroups: 'Todos los grupos',
        employees: 'Seleccione empleados',
        selectedStatus: 'Seleccione estado',
        status: {
            pending: 'Pendiente',
            accepted: 'Aceptado',
            retracted: 'Retractado',
            rejected: 'Rechazado',
        }
    },
    alert:{
        approve: {
            title: 'Solicitud aprobada',
            detail: 'La solicitud fue aprobada correctamente.'
        },
        approveError: {
            title: 'Error al aprobar solicitud',
            detail: 'Ocurrió un error al intentar aprobar la solicitud.'
        },
        rejected: {
            title: 'Solicitud rechazada',
            detail: 'La solicitud fue rechazada correctamente.'
        },
        rejectedError: {
            title: 'Error al rechazar solicitud',
            detail: 'Ocurrió un error al intentar rechazar la solicitud.'
        },
        created: {
            title: 'Solicitud creada',
            detail: 'La solicitud se ha creado correctamente.'
        },
        createdError: {
            title: 'Error',
            detail: 'La solicitud no se ha podido crear.'
        }
    },
    dialogAdd:{
        title: "Agregar solicitudes",
        allGroups: 'Todos los grupos',
        employees: 'Seleccione empleados',
        selectedType: 'Seleccione tipo de solicitud',
        description: "Descripción",
        uploadFile: "Subir archivo",
        dragAndrop: "Arrastre y suelte los archivos aquí para cargarlos.",
        buttons: {
            save: "Guardar",
            cancel: "Cancelar"
        }
    },
    changePasswordMessages:{
        error: "No se ha podido cambiar las contraseñas",
        success: "Contraseña cambiada con éxito",
        info:"Contraseñas no son iguales",
        warning:"No se ha podido cambiar las contraseñas, ambas no son iguales",
        required: "Por favor, rellene todos los campos obligatorios."
    },
    dialogDetail: {
        title: "Detalle de la solicitud",
        inputs: {
            paycode: "Código de pago",
            created_by: "Creado por",
            start_date: "Fecha de inicio",
            end_date: "Fecha de término",
            created_at: "Fecha de creación",
            status: "Estado",
            employee_comment: "Comentario del colaborador",
            manager_comment: "Comentario del manager",
        },
        buttons: {
            show_document: "Mostrar documento",
        }
    },
    kronoStatusModal: {
        title: "Estado en Kronos:",
        table: {
            date:"Fecha",
            status:"Estado",
            paycode:"Código de pago",
            message:"Mensaje",
        }

    }
}

// Peruano
export const peRequestTable = {
    table: {
        person_number: 'Número personal',
        fullname: 'Nombre completo',
        paycode: 'Código de pago',
        create_at: 'Fecha creación',
        startdate: 'Fecha inicio',
        endate: 'Fecha término',
        manager: 'Manager',
        status: 'Estado',
        kronostatus: 'Kronos estado',
    },
    menu:{
        accept: 'Aceptar',
        reject: 'Rechazar',
        refuse: 'Rehusar',
        information: 'Información',
        detail: 'Detalle',
        add: 'Agregar'
    },
    filter:{
        allGroups: 'Todos los grupos',
        employees: 'Seleccione empleados',
        selectedStatus: 'Seleccione estado',
        status: {
            pending: 'Pendiente',
            accepted: 'Aceptado',
            retracted: 'Retractado',
            rejected: 'Rechazado',
        }
    },
    alert:{
        approve: {
            title: 'Solicitud aprobada',
            detail: 'La solicitud fue aprobada correctamente.'
        },
        approveError: {
            title: 'Error al aprobar solicitud',
            detail: 'Ocurrió un error al intentar aprobar la solicitud.'
        },
        rejected: {
            title: 'Solicitud rechazada',
            detail: 'La solicitud fue rechazada correctamente.'
        },
        rejectedError: {
            title: 'Error al rechazar solicitud',
            detail: 'Ocurrió un error al intentar rechazar la solicitud.'
        },
        created: {
            title: 'Solicitud creada',
            detail: 'La solicitud se ha creado correctamente.'
        },
        createdError: {
            title: 'Error',
            detail: 'La solicitud no se ha podido crear.'
        }
    },
    dialogAdd:{
        title: "Agregar solicitudes",
        allGroups: 'Todos los grupos',
        employees: 'Seleccione empleados',
        selectedType: 'Seleccione tipo de solicitud',
        description: "Descripción",
        uploadFile: "Subir archivo",
        dragAndrop: "Arrastre y suelte los archivos aquí para cargarlos.",
        buttons: {
            save: "Guardar",
            cancel: "Cancelar"
        }
    },
    changePasswordMessages:{
        error: "No se ha podido cambiar las contraseñas",
        success: "Contraseña cambiada con éxito",
        info:"Contraseñas no son iguales",
        warning:"No se ha podido cambiar las contraseñas, ambas no son iguales",
        required: "Por favor, rellene todos los campos obligatorios."
    },
    dialogDetail: {
        title: "Detalle de la solicitud",
        inputs: {
            paycode: "Código de pago",
            created_by: "Creado por",
            start_date: "Fecha de inicio",
            end_date: "Fecha de término",
            created_at: "Fecha de creación",
            status: "Estado",
            employee_comment: "Comentario del colaborador",
            manager_comment: "Comentario del manager",
        },
        buttons: {
            show_document: "Mostrar documento",
        }
    },
    kronoStatusModal: {
        title: "Estado en Kronos:",
        table: {
            date:"Fecha",
            status:"Estado",
            paycode:"Código de pago",
            message:"Mensaje",
        }

    }
}

// English
export const enRequestTable = {
    table: {
        person_number: 'Person Number',
        fullname: 'Full Name',
        paycode: 'PayCode',
        create_at: 'Create At',
        startdate: 'Start Date',
        endate: 'End Date',
        manager: 'Manager',
        status: 'Status',
        kronostatus: 'Kronos State',
    },
    menu:{
        accept: 'Accept',
        reject: 'Reject',
        refuse: 'Refuse',
        information: 'Information',
        detail: 'Detail',
        add: 'Add'
    },
    filter:{
        allGroups: 'All Groups',
        employees: 'Employees',
        selectedStatus: 'Select status',
        status: {
            pending: 'Pending',
            accepted: 'Accepted',
            retracted: 'Retracted',
            rejected: 'Rejected',
        }
    },
    alert:{
        approve: {
            title: 'Request aproved',
            detail: 'The request was successfully approved.'
        },
        approveError: {
            title: 'Failed approving request',
            detail: 'An error occurred while trying to approve the request.'
        },
        rejected: {
            title: 'Rejected request',
            detail: 'The request was successfully rejected.'
        },
        rejectedError: {
            title: 'Error rejecting request',
            detail: 'An error occurred while trying to reject the request.'
        },
        created: {
            title: 'Request created',
            detail: 'The request has been created correctly.'
        },
        createdError: {
            title: 'Error',
            detail: 'The request could not be created.'
        }
    },
    dialogAdd:{
        title: "Add request",
        allGroups: 'All Groups',
        employees: 'Employees',
        selectedType: 'Select request type',
        description: "Description",
        uploadFile: "Upload file",
        dragAndrop: "Drag and drop files here to upload.",
        buttons: {
            save: "Save",
            cancel: "Cancel"
        }
    },
    changePasswordMessages:{
        error: "Error changing passwords",
        success: "Password changed successfully",
        info:"Passwords are not the same",
        warning:"Failed to change passwords, both are not the same",
        required: "Please fill out all the required fields."
    },
    dialogDetail: {
        title: "Request details",
        inputs: {
            paycode: "Paycode",
            created_by: "Created by",
            start_date: "Start date",
            end_date: "End date",
            created_at: "Created at",
            status: "Status",
            employee_comment: "Employee comment",
            manager_comment: "Manager comment",
        },
        buttons: {
            show_document: "Show document",
        }
    },
    kronoStatusModal: {
        title: "Kronos Status:",
        table: {
            date:"Date",
            status:"Status",
            paycode:"Pay Code",
            message:"Message",
        }

    }
}