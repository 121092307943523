// Español
export const esStepper = {
    steps: {
        reports: 'Seleccionar informe',
        parameters: 'Seleccionar parámetros',
    },
    buttons: {
        next: 'Siguiente',
        back: 'Atrás',
        done: 'Generar'
    },
    table:{
        person_number: 'Número personal',
        full_name: 'Nombre completo',
        orgpath: 'Nivel organizacional',
        empty: 'No se encontraron empleados.',
        loading: 'Cargando datos de empleados. Espere por favor.',
        search: 'Búsqueda por palabra clave',
        dropdown: 'Todos los grupos'
    }
}

// Español peruano
export const peStepper = {
    steps: {
        reports: 'Seleccionar informe',
        parameters: 'Seleccionar parámetros',
    },
    buttons: {
        next: 'Siguiente',
        back: 'Atrás',
        done: 'Generar'
    },
    table:{
        person_number: 'Número personal',
        full_name: 'Nombre completo',
        orgpath: 'Nivel organizacional',
        empty: 'No se encontraron empleados.',
        loading: 'Cargando datos de empleados. Espere por favor.',
        search: 'Búsqueda por palabra clave',
        dropdown: 'Todos los grupos'
    }
}

// English
export const enStepper = {
    steps: {
        reports: 'Select report',
        parameters: 'Select parameters',
    },
    buttons: {
        next: 'Next',
        back: 'Back',
        done: 'Generate'
    },
    table:{
        person_number: 'Person number',
        full_name: 'Full name',
        orgpath: 'Org path',
        empty: 'No employees found.',
        loading: 'Loading employees data. Please wait.',
        search: 'Keyword Search',
        dropdown: 'All Groups'
    },
}