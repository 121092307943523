// Español
export const esError = {
    employees: 'Esta cuenta puede ver la página colaboradores',
    timecard: 'Esta cuenta puede ver la página hoja de horas',
    schedule: 'Esta cuenta puede ver la página horarios',
    reports: 'Esta cuenta puede ver la página reportes',
    contact: 'Contáctese con su administrador si necesita acceso a la vista'
}

// Español peruano
export const peError = {
    employees: 'Esta cuenta puede ver la página colaboradores',
    timecard: 'Esta cuenta puede ver la página hoja de horas',
    schedule: 'Esta cuenta puede ver la página horarios',
    reports: 'Esta cuenta puede ver la página reportes',
    contact: 'Contáctese con su administrador si necesita acceso a la vista'
}


// English
export const enError = {
    employees: 'This account can´t see the employees page',
    timecard: 'This account can´t see the timecard page',
    schedule: 'This account can´t see the schedule page',
    reports: 'This account can´t see the reports page',
    contact: 'Contact your administrator if you need access to the view'
}