import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{ 
		path: '/', 
		name: 'home', 
		component: () => import('../views/Employees.vue') 
	},
	{ 
		path: '/employees', 
		name: 'employees',
		redirect: '/',
		component: () => import('../views/Employees.vue') 
	},
	{ 
		path: '/schedule',
		name: 'schedule',
		component: () => import('../views/Schedule.vue'),

	},
	{ 
		path: '/time-card',
		name: 'time-card',
		component: () => import('../views/TimeCard.vue') 
	},
	{ 
		path: '/overtime',
		name: 'overtime',
		component: () => import('../views/Overtime.vue') 
	},
	{ 
		path: '/refactor/time-card',
		name: 'refactor-time-card',
		component: () => import('../views/refactor/Timecard.vue') 
	},
	{ 
		path: '/generator',
		name: 'reports',
		component: () => import('../views/Reports.vue') 
	},
	{ 
		path: '/results',
		name: 'results',
		component: () => import('../views/Results.vue') 
	},
	{ 
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue') 
	},
	{ 
		path: '/FormIdentifier',
		name: 'form',
		component: () => import('../views/FormIdentifier.vue') 
	},
	{ 
		path: '/request',
		name: 'request',
		component: () => import('../views/Request.vue') 
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
