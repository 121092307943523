/* eslint-disable vue/multi-word-component-names */
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';
import './assets/demo/flags/flags.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import "vue3-pdf-app/dist/icons/main.css";

import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Steps from 'primevue/steps';
import PrimeVue from 'primevue/config';
import Tree from 'primevue/tree';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Badge from 'primevue/badge';
import Calendar from 'primevue/calendar';
import Sidebar from 'primevue/sidebar';
import Dropdown from 'primevue/dropdown';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Toolbar from 'primevue/toolbar';
import ToggleButton from 'primevue/togglebutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Divider from 'primevue/divider';
import Dialog from 'primevue/dialog';
import PickList from 'primevue/picklist';
import MultiSelect from 'primevue/multiselect';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';
import ContextMenu from 'primevue/contextmenu';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import SplitButton from 'primevue/splitbutton';
import InlineMessage from 'primevue/inlinemessage';
import ProgressSpinner from 'primevue/progressspinner';
import Image from 'primevue/image';
import FileUpload from 'primevue/fileupload';
import Avatar from 'primevue/avatar';
import Tag from 'primevue/tag';
import InputNumber from 'primevue/inputnumber';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Card from 'primevue/card';
import Fieldset from 'primevue/fieldset';
import Panel from 'primevue/panel';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Editor from 'primevue/editor';

import PdfApp from "vue3-pdf-app";
import JsonViewer from 'vue-json-viewer'

import App from './App.vue'
import router from './router'

import Emitter from 'tiny-emitter'
import { createApp } from 'vue'
import { i18n } from './i18n/i18n';


const app = createApp(App);
app.use(i18n)

const options = {}

app.use(PrimeVue, options);

app.use(router);
app.use(ToastService);

app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();

app.component('InputText', InputText);
app.component('Password', Password);
app.component('Checkbox', Checkbox);
app.component('Button', Button);
app.component('Steps', Steps);
app.component('Tree', Tree);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Badge', Badge);
app.component('Calendar', Calendar);
app.component('Sidebar', Sidebar);
app.component('Dropdown', Dropdown);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Divider', Divider);
app.component('Dialog', Dialog);
app.component('PickList', PickList);
app.component('MultiSelect', MultiSelect);
app.component('Textarea', Textarea);
app.component('ContextMenu', ContextMenu);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('SplitButton', SplitButton);
app.component('InlineMessage', InlineMessage);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Image', Image);
app.component('FileUpload', FileUpload);
app.component('Avatar', Avatar);
app.component('Tag', Tag);
app.component('InputNumber', InputNumber);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Card', Card);
app.component('Fieldset', Fieldset);
app.component('Panel', Panel);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('Editor', Editor);

app.component('JsonViewer', JsonViewer);
app.component('PdfApp', PdfApp);

app.directive('tooltip', Tooltip);

app.mount('#app');

window.app = app;