// Español
export const esScheduleTable = {
    table: {
        person_number: 'Número personal',
        fullname: 'Nombre completo',
        totals: 'Total horas planificadas',
    },
    menu:{
        add_paycode: 'Agregar código de pago',
        add_shift: 'Agregar turno',
        clean_schedule: 'Limpiar horario'
    },
    dialog:{
        add_shift: {
            title: 'Agregar turnos',
            person_number: 'Número personal',
            fullname: 'Nombre completo',
            dates: 'Fechas',
            table: {
                add_segment: 'Agregar segmento',
                starttime: 'Hora de entrada',
                endtime: 'Hora de salida',
                tagname: 'Tipo'
            },
            toast:{
                title: 'Campos vacíos',
                message: 'Por favor complete todos los campos para generar un turno',
                warning: 'No se pueden modificar elementos del horario anterior a la fecha de contratación',
                success: 'Turno generado con éxito',
                error: 'Error al generar turno',
                title_warnning: 'Advertencia',
                title_success: 'Éxito',
                addPayCodeDays: {
                    title: 'Agregar código de pago días',
                    warning: 'No se pueden modificar elementos del horario anterior a la fecha de contratación.',
                    success: 'Código de pago generado exitosamente',
                },
                addPayCodeHours:{
                    title: 'Agregar código de pago horas',
                    warning: 'No se pueden modificar elementos del horario anterior a la fecha de contratación.',
                    success: 'Código de pago generado exitosamente',
                }
            }
        },
        clean_schedule:{
            title: 'Limpiar horario',
            dates: 'Fechas',
            clean: 'Limpiar'
        },
        add_paycodes:{
            title: 'Agregar código de pago',
            person_number: 'Número personal',
            fullname: 'Nombre completo',
            paycode: 'Código de pago',
            type: 'Tipo de monto',
            amount: 'Monto',
            dates: 'Fechas',
            start_time: 'Hora de inicio',
            button: 'Agregar códigos de pago',
            full_contract_day: 'Jornada completa de contratación',
            full_sched_day: 'Jornada completa planificada',
            pattern_day: 'Día de patrón',
            override: 'Reemplazar turno',
            toast:{
                title: 'Campos vacíos',
                message: 'Por favor complete todos los campos para generar un turno',
            }
        },
        add_massive_shifts:{
            title: 'Agregar turnos masivo',
            uploadFile: 'Subir archivo',
            replaceShifts: 'Reemplazar turnos',
            downloadCsv: 'Descargar format CSV',
            drawDrop: 'Arrastre y suelte archivos aquí para cargarlos.',
            invalidFileSizeMessage: 'El tamaño del archivo es demasiado grande',
            invalidFileContentMessage: 'El archivo no contiene información',
            downloadExample: 'Descargar ejemplo de plantilla',
            override: 'Reemplazar turno',
            infoFile: 'Si no tienes claro el formato para subir el archivo, descarga el ejemplo aquí:',
            infoReplaceShift: 'Al marcarlos, reemplazan los horarios que ya están registrados en los días que inserto en el archivo csv',
            tableFile: {
                person_number: 'Número personal',
                date: 'Fecha',
                type: 'Tipo',
                start_time: 'Hora de entrada',
                end_time: 'Hora de salida',
            }

        }
    },
    filter:{
        allGroups: 'Todos los grupos',
        employees: 'Seleccione empleados'
    }
}

// Español peruano
export const peScheduleTable = {
    table: {
        person_number: 'Número personal',
        fullname: 'Nombre completo',
        totals: 'Total horas planificadas',
    },
    menu:{
        add_paycode: 'Agregar ausentismo justificado',
        add_shift: 'Agregar turno',
        clean_schedule: 'Limpiar horario'
    },
    dialog:{
        add_shift: {
            title: 'Agregar turnos',
            person_number: 'Número personal',
            fullname: 'Nombre completo',
            dates: 'Fechas',
            table: {
                add_segment: 'Agregar segmento',
                starttime: 'Hora de entrada',
                endtime: 'Hora de salida',
                tagname: 'Tipo'
            },
            toast:{
                title: 'Campos vacíos',
                message: 'Por favor complete todos los campos para generar un turno',
                warning: 'No se pueden modificar elementos del horario anterior a la fecha de contratación',
                success: 'Turno generado con éxito',
                error: 'Error al generar turno',
                title_warnning: 'Advertencia',
                title_success: 'Éxito',
                addPayCodeDays: {
                    title: 'Agregar código de pago días',
                    warning: 'No se pueden modificar elementos del horario anterior a la fecha de contratación.',
                    success: 'Código de pago generado exitosamente',
                },
                addPayCodeHours:{
                    title: 'Agregar código de pago horas',
                    warning: 'No se pueden modificar elementos del horario anterior a la fecha de contratación.',
                    success: 'Código de pago generado exitosamente',
                }
            }
        },
        clean_schedule:{
            title: 'Limpiar horario',
            dates: 'Fechas',
            clean: 'Limpiar'
        },
        add_paycodes:{
            title: 'Agregar ausentismo justificado',
            person_number: 'Número personal',
            fullname: 'Nombre completo',
            paycode: 'Ausentismo justificado',
            type: 'Tipo variable',
            amount: 'Cantidad',
            dates: 'Fechas',
            start_time: 'Hora de inicio',
            button: 'Agregar ausentismo justificado',
            full_contract_day: 'Jornada completa de contratación',
            full_sched_day: 'Jornada completa planificada',
            pattern_day: 'Día de patrón',
            override: 'Reemplazar turno',
            toast:{
                title: 'Campos vacíos',
                message: 'Por favor complete todos los campos para generar un turno',
            }
        },
        add_massive_shifts:{
            title: 'Agregar turnos masivo',
            uploadFile: 'Subir archivo',
            replaceShifts: 'Reemplazar turnos',
            downloadCsv: 'Descargar format CSV',
            drawDrop: 'Arrastre y suelte archivos aquí para cargarlos.',
            invalidFileSizeMessage: 'El tamaño del archivo es demasiado grande',
            invalidFileContentMessage: 'El archivo no contiene información',
            downloadExample: 'Descargar ejemplo de plantilla',
            override: 'Reemplazar turno',
            infoFile: 'Si no tienes claro el formato para subir el archivo, descarga el ejemplo aquí:',
            infoReplaceShift: 'Al marcarlos, reemplazan los horarios que ya están registrados en los días que inserto en el archivo csv',
            tableFile: {
                person_number: 'Número personal',
                date: 'Fecha',
                type: 'Tipo',
                start_time: 'Hora de entrada',
                end_time: 'Hora de salida',
            }
        }
    },
    filter:{
        allGroups: 'Todos los grupos',
        employees: 'Seleccione empleados'
    }
}

// English
export const enScheduleTable = {
    table: {
        person_number: 'Person number',
        fullname: 'Full name',
        totals: 'Total planned hours',
    },
    menu:{
        add_paycode: 'Add paycode',
        add_shift: 'Add shift',
        clean_schedule: 'Clean schedule'
    },
    dialog:{
        add_shift: {
            title: 'Add shifts',
            person_number: 'Person number',
            fullname: 'Full name',
            dates: 'Dates',
            table: {
                add_segment: 'Add segment',
                starttime: 'Start time',
                endtime: 'End time',
                tagname: 'Tag name'
            },
            toast:{
                title: 'Empty fields',
                message: 'Please complete all the fields to generate a shift',
                warning: 'Cannot modify elements of the schedule prior to the hire date',
                success: 'Successfully generated shift',
                error: 'Error generating shift',
                title_warnning: 'Warning',
                title_success: 'Success',
                addPayCodeDays: {
                    title: 'Add paycode Days',
                    warning: 'Schedule elements prior to the hire date cannot be modified.',
                    success: 'Successfully generated paycode',
                },
                addPayCodeHours: {
                    title: 'Add paycode hours',
                    warning: 'Schedule elements prior to the hire date cannot be modified.',
                    success: 'Successfully generated paycode',
                }
            }
        },
        clean_schedule:{
            title: 'Clean schedule',
            dates: 'Dates',
            clean: 'Clean'
        },
        add_paycodes:{
            title: 'Add paycode',
            person_number: 'Person number',
            fullname: 'Full name',
            paycode: 'Paycode',
            type: 'Type of amount',
            amount: 'Amount',
            dates: 'Dates',
            start_time: 'Start time',
            button: 'Add paycode',
            full_contract_day: 'full contract day',
            full_sched_day: 'full sched day',
            pattern_day: 'pattern day',
            override: 'Replace shift',
            toast:{
                title: 'Empty fields',
                message: 'Please complete all the fields to generate a shift',
            }
        },
        add_massive_shifts:{
            title: 'Add massive shifts',
            uploadFile: 'Upload file',
            replaceShifts: 'Replace shifts',
            downloadCsv: 'Download CSV format',
            drawDrop: 'Drag and drop files here to upload.',
            invalidFileSizeMessage: 'File size is too large',
            invalidFileContentMessage: 'File does not contain information',
            downloadExample: 'Download template example',
            override: 'Replace shift',
            infoFile: 'If you are not clear about the format to upload the file, download the example here:',
            infoReplaceShift: 'When checking, they replace the schedules that are already registered in the days that I insert in the csv file',
            tableFile: {
                person_number: 'Person number',
                date: 'Date',
                type: 'Type',
                start_time: 'Start Time',
                end_time: 'End Time',
            }
        }
    },
    filter:{
        allGroups: 'All Groups',
        employees: 'Select a employees'
    }
}
