<template>
	<div :class="containerClass">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {
				layoutMode: 'static',
				staticMenuInactive: false,
				overlayMenuActive: false,
				mobileMenuActive: false,
			}
		},
		components: {
		},
		computed: {
			containerClass() {
				return [
					'layout-wrapper', 
					{
						'layout-overlay': this.layoutMode === 'overlay',
						'layout-static': this.layoutMode === 'static',
						'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
						'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
						'layout-mobile-sidebar-active': this.mobileMenuActive,
						'p-input-filled': this.$primevue.config.inputStyle === 'filled',
						'p-ripple-disabled': this.$primevue.config.ripple === false
					}
				];	
			}
		},
		created(){
			document.title = 'Easy Manager'
			
			let locale = sessionStorage.getItem('language')

			const getLocale = (locale) => {
				switch(locale){
					case 'pe':
						return 'pe'
					case 'es':
						return 'es'
					case 'en':
						return 'en'
					default:
						return navigator.language.slice(0,2)
				}
			}

			this.$i18n.locale = getLocale(locale)
		}
	}
</script>

<style lang="scss">
	@import './App.scss';
</style>

