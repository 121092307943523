export const esEmployeeTable = {
    table: {
        person_id: 'Número personal',
        fullname: 'Nombre completo',
        hire_date: 'Fecha de contratación',
        orgpath: 'Nivel Organizacional',
        empty: 'No se encontraron datos relacionados.',
        goto: 'Ir a',
        schedule: 'Ir a horario',
        timecard: 'Ir a Hoja de horas',
        charging_table: 'Cargando información ...'
    },
    menu:{
        add_paycode: 'Agregar código de pago',
        add_shift: 'Agregar turno',
        clean_schedule: 'Limpiar Horario'
    },
    filter:{
        allGroups: 'Todos los grupos',
        employees: 'Seleccione empleados',
        startsWith: 'Comienza con',
        contains: 'Contiene',
        endsWith: 'Termina con',
        equals: 'Igual a',
        notEquals: 'No es igual a',
    }
}
export const peEmployeeTable = {
    table: {
        person_id: 'Número personal',
        fullname: 'Nombre completo',
        hire_date: 'Fecha de contratación',
        orgpath: 'Nivel Organizacional',
        empty: 'No se encontraron datos relacionados.',
        goto: 'Ir a',
        schedule: 'Ir a horario',
        timecard: 'Ir a Hoja de horas',
        charging_table: 'Cargando información ...'
    },
    menu:{
        add_paycode: 'Agregar código de pago',
        add_shift: 'Agregar turno',
        clean_schedule: 'Limpiar Horario'
    },
    filter:{
        allGroups: 'Todos los grupos',
        employees: 'Seleccione empleados',
        startsWith: 'Comienza con',
        contains: 'Contiene',
        endsWith: 'Termina con',
        equals: 'Igual a',
        notEquals: 'No es igual a',
    }
}
export const enEmployeeTable = {
    table: {
        person_id: 'Person id',
        fullname: 'Full name',
        hire_date: 'Hire Date',
        orgpath: 'Org path',
        empty: 'No related data found',
        goto: 'Go to',
        schedule: 'Go to Schedule',
        timecard: ' Go to Time Card',
        charging_table: 'Loading information ...'
    },
    menu:{
        add_paycode: 'Add paycode',
        add_shift: 'Add shift',
        clean_schedule: 'Clean schedule'
    },
    filter:{
        allGroups: 'All Groups',
        employees: 'Select a employees',
        startsWith: 'Starts with',
        contains: 'Contains',
        endsWith: 'EndsWith',
        equals: 'Equals',
        notEquals: 'Not equals',
    }
}